<template>
  <section class="concern-wrap">
    <!-- 最近更新 -->
    <section class="recent-updates" v-if="recentUpdatesLists.length >= 3">
      <h3 class="title"><i class="title-icon"></i>最近更新</h3>
      <ul class="recent-updates-lists">
        <li class="recent-updates-item" v-for="item in recentUpdatesLists" :key="item.talk_id" @click="openTalk(item.talk_id)">
          <section class="item-wrap">
            <section class="item-left">
              <img :src="item.talk_img" alt="" />
            </section>
            <section class="item-right">
              <h4>{{ item.talk_name }}</h4>
            </section>
          </section>
        </li>
      </ul>
    </section>

    <!-- 关注列表 -->
    <section class="concern-lists-wrap" ref="concernListsWrap">
      <h3 class="no-concern-title" v-if="noConcern">未关注任何话题，推荐以下话题</h3>
      <ul class="concern-lists" :class="{ 'no-concern': noConcern }">
        <li class="concern-lists-item" v-for="item in concernLists" :key="item.talk_id" @click="openTalk(item.talk_id)">
          <section class="item-left">
            <img :src="item.talk_img" alt="" />
          </section>
          <section class="item-right">
            <section class="item-right-top">
              <h4>{{ item.talk_name }}</h4>
              <p class="concern-btn" :class="{ 'concern-has': item.is_user_concern !== 1 }" @click.stop="concernBtn({ talk_id: item.talk_id, is_user_concern: item.is_user_concern })">{{ item.is_user_concern !== 1 ? '+关注' : '已关注' }}</p>
            </section>
            <p class="desc">{{ item.talk_desc }}</p>
          </section>
        </li>
      </ul>
    </section>

    <!-- 取消关注 dialog -->
    <section class="unfollow-dialog-wrap">
      <el-dialog :visible.sync="unfollowDialogVisible" :show-close="false">
        <p class="unfollow-tips">确认是否取消关注？</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="unfollowDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="unfollowBtn">确 定</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { getRecentNews, recomendConcern, getUserConcernList, userConcern } from '@/api/axios.request';

export default {
  name: 'concern',
  data() {
    return {
      // 最近更新
      recentUpdatesLists: [],
      // 请求参数
      concernRequireObject: {
        scrollFlag: true,
        page_no: 1, // 页码，不传则默认请求第 1 页
        page_size: 10 // 每页条数，不传则默认每页 10 条
      },
      // 关注列表
      concernLists: [],
      noConcern: false, // 用户没有关注的话题
      // 对话题的操作信息
      talkObject: {},
      // 取消关注 flag
      unfollowFlag: false,
      // 取消关注 dialog
      unfollowDialogVisible: false
    };
  },
  watch: {
    unfollowDialogVisible: {
      handler(curVal) {
        this.unfollowFlag = curVal;
      }
    }
  },
  methods: {
    // 获取动态列表 最近更新
    getRecentNews() {
      let _this = this;

      getRecentNews().then((response) => {
        const { code, result } = response.data;

        if (code === 200) {
          _this.recentUpdatesLists = (result || []).slice(0, 3);
        }
      });
    },
    // 获取用户关注的话题列表
    getUserConcernList() {
      let _this = this;

      let { page_no, page_size } = _this.concernRequireObject;

      getUserConcernList({ page_no, page_size }).then((response) => {
        const { code, result } = response.data;
        let tempResultArr = result || [];

        if (code === 200) {
          _this.concernLists = _this.concernLists.concat(
            tempResultArr.map((element) => {
              return { ...element, is_user_concern: 1 };
            })
          );
        }

        // 如果用户没有关注话题，则请求推荐关注话题列表
        if (_this.concernLists.length <= 0 && _this.concernRequireObject.page_no <= 1) {
          _this.noConcern = true;

          recomendConcern().then((response2) => {
            const { code, result } = response2.data;

            if (code === 200) {
              _this.concernLists = (result || []).map((element) => {
                return { ...element, is_user_concern: 0 };
              });
            }
          });
        }

        ++_this.concernRequireObject.page_no;

        if (tempResultArr.length >= 10) {
          _this.concernRequireObject.scrollFlag = true;
        }
      });
    },
    // 取消 / 关注按钮
    concernBtn(info) {
      let _this = this;

      if (_this.unfollowFlag) return;

      if (info.is_user_concern === 1) {
        // 取消关注
        _this.talkObject = info;

        _this.unfollowDialogVisible = true;
      } else {
        // 关注
        _this.talkObject = info;

        userConcern({ talk_id: info.talk_id, op_type: '1' }).then((response) => {
          const { code } = response.data;

          if (code === 200) {
            _this.concernLists.forEach((element) => {
              if (element.talk_id === info.talk_id) {
                element.is_user_concern = 1;
              }
            });

            _this.msgSuccess('关注话题成功！');
          }
        });
      }
    },
    // 打开话题页
    openTalk(talk_id) {
      let routeUrl = this.$router.resolve({
        path: `/talk/${talk_id}`
      });

      window.open(routeUrl.href, '_blank');
    },
    // 取消关注 弹窗 确认按钮
    unfollowBtn() {
      let _this = this;

      userConcern({ talk_id: _this.talkObject.talk_id, op_type: '0' }).then((response) => {
        const { code } = response.data;

        if (code === 200) {
          _this.concernLists.forEach((element) => {
            if (element.talk_id === _this.talkObject.talk_id) {
              element.is_user_concern = 0;
            }
          });

          _this.talkObject = {};
          _this.unfollowDialogVisible = false;

          _this.msgSuccess('取消关注话题成功！');
        }
      });
    },
    // 滚动事件
    scrollEvent() {
      let _this = this;

      let WH = window.innerHeight;
      let ST = document.documentElement.scrollTop;
      let concernListsWrapHeight = _this.$refs.concernListsWrap.offsetHeight;

      if (WH + ST + 100 >= 217 + 54 + concernListsWrapHeight && _this.concernRequireObject.scrollFlag) {
        _this.concernRequireObject.scrollFlag = false;

        _this.getUserConcernList();
      }
    }
  },
  created() {
    this.getRecentNews();
  },
  mounted() {
    this.scrollEvent();

    window.addEventListener('scroll', this.scrollEvent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent);
  }
};
</script>

<style lang="scss" scoped>
.concern-wrap {
  width: 100%;
  background-color: #f7f9fa;

  .recent-updates {
    background-color: #fff;

    .title {
      padding: 15px;
      height: 23px;
      line-height: 24px;
      font-size: 16px;
      border-bottom: 1px solid #f4f4f4;

      .title-icon {
        display: inline-block;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        background: url('../../assets/image/icon1.png') 0 -96px;
        vertical-align: top;
      }
    }

    .recent-updates-lists {
      margin-bottom: 10px;
      padding: 30px 15px;
      overflow: hidden;

      .recent-updates-item {
        float: left;
        padding: 0 15px;
        width: 33%;
        box-sizing: border-box;

        .item-wrap {
          cursor: pointer;
          overflow: hidden;

          .item-left {
            float: left;
            width: 68px;
            height: 55px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .item-right {
            padding-left: 10px;
            overflow: hidden;

            h4 {
              font-size: 14px;
              font-weight: normal;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }

          &:hover {
            .item-right {
              h4 {
                color: #1e88ff;
              }
            }
          }
        }
      }
    }
  }

  .concern-lists-wrap {
    background-color: #fff;

    .no-concern-title {
      padding: 15px 30px;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 1px solid #f4f4f4;
    }

    .concern-lists {
      padding: 30px;
      cursor: pointer;

      &.no-concern {
        padding: 20px 30px 30px;
      }

      .concern-lists-item {
        padding: 20px 0;
        border-bottom: 1px solid #eeeeee;

        .item-left {
          float: left;
          width: 120px;
          height: 90px;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .item-right {
          padding-left: 20px;
          height: 90px;
          overflow: hidden;

          .item-right-top {
            position: relative;

            h4 {
              width: calc(100% -70px);
              height: 28px;
              line-height: 28px;
              font-size: 18px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .concern-btn {
              position: absolute;
              top: 0;
              right: 0;
              width: 62px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              font-size: 14px;
              border-radius: 14px;
              color: #1985ff;
              border: solid 1px #1985ff;

              &:hover {
                color: #fff;
                background-color: #1985ff;
              }

              &.concern-has {
                color: #aaaaaa;
                border: solid 1px #aaaaaa;

                &:hover {
                  color: #fff;
                  background-color: #aaaaaa;
                }
              }
            }
          }

          .desc {
            margin-top: 15px;
            line-height: 24px;
            font-size: 15px;
            color: #666666;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }

        &:hover {
          background-color: #f7f8f9;

          .item-right {
            h4 {
              color: #1e88ff;
            }
          }
        }
      }
    }
  }

  .unfollow-dialog-wrap {
    /deep/ .el-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0 !important;
      width: auto;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 65px 0 40px;
        width: 360px;

        .unfollow-tips {
          color: #333333;
          font-size: 18px;
          text-align: center;
        }
      }

      .el-dialog__footer {
        text-align: center;
      }
    }
  }
}
</style>
